<template>
  <div class="wet-section wet-reviews">
    <div class="container">
      <div class="wet-section-title text-center empty-ph mt-4" v-html="dataValid.title"></div>
      <div class="wet-reviews-slider" >
        <button
          class="slick-prev slick-arrow"
          aria-label="Previous"
          type="button"
          aria-disabled="false"
          style="display: block;"
          @click="sliderLeft"
        ></button>
        <!-- <div class="col"> -->
          <template v-for="(item, id) in dataValid.reviews" >
            <transition name="fade-absolute" :key="id" @enter="compRowHeight">
              <div class="wet-reviews-item slide" v-if="current === id">
                <reactive-picture
                  v-if="item.rewiews_photo.sizes"
                  :image="item.rewiews_photo"
                  class="wet-reviews-item-photo"
                  :size-collection-type="wpImageSizeCollectionType.SMALL"
                  :width=200
                  :height=200
                />
                <img
                  v-else
                  :width=200
                  :height=200
                />
                <div class="d-flex align-items-center" style="height:100%;">
                  <div class="d-flex flex-column w-100">
                    <div class="wet-reviews-item-text">
                      <p class="empty-ph">{{item.rewiews_text}}</p>
                    </div>
                    <div class="wet-reviews-item-footer">
                      <b class="empty-ph">{{item.name}}</b>
                      <span class="empty-ph">{{item.position}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </template>
        <!-- </div> -->
        <button
          class="slick-next slick-arrow"
          aria-label="Next"
          type="button"
          style="display: block;"
          aria-disabled="false"
          @click="sliderRight"
        ></button>
      </div>
    </div>
  </div>
</template>
<script>
import wpImageSizeCollectionType from '@/enums/wpImageSizeCollectionType';

export default {
  props: {
    data: Object,
  },
  components: {
    reactivePicture: () => import(/* webpackChunkName: "wp-picture" */ '@/components/ui/reactive-pictureUi.vue'),
  },
  data() {
    return {
      wpImageSizeCollectionType,
      current: 0,
      rowHeight: 0,
    };
  },
  computed: {
    dataValid() {
      // valid and defaults data
      if (this.data.reviews) {
        return this.data;
      } return {
        title: '',
        reviews: [
          {
            reviews_photo: { url: '' },
            reviews_text: '',
            name: '',
            position: '',
          },
        ],
      };
    },
  },
  mounted() {
    // this.compRowHeight()
    this.$root.$on('resize', this.resize);
    this.slide();
  },
  destroyed() {
    // unwatch
    this.$root.$off('resize', this.resize);
  },
  watch: {
    data() {
      this.compRowHeight();
    },
  },
  methods: {
    slide() {
      // mouse controller slide
      if (document) {
        const row = document.querySelector('.wet-reviews-slider');
        if (row) {
          const rowTouch = new this.$hammer(row);
          rowTouch.on('swipeleft', this.sliderLeft);
          rowTouch.on('swiperight', this.sliderRight);
        }
      }
    },
    compRowHeight() {
      // for slow height row changing
      if (document) {
        const slide = document.querySelector('.wet-reviews-item.slide.fade-absolute-enter-active');
        const row = document.querySelector('.wet-reviews-slider');
        if (slide && row) {
          const marginTop = parseInt(getComputedStyle(slide).marginTop, 10) || 0;
          const marginBottom = parseInt(getComputedStyle(slide).marginBottom, 10) || 0;
          row.style.height = `${slide.offsetHeight + marginTop + marginBottom}px`;
        }
      }
    },
    sliderLeft() {
      // event on slider left btn click
      if (this.current === 0) {
        this.current = this.data.reviews.length - 1;
      } else this.current -= 1;
    },
    sliderRight() {
      // event on slider right btn click
      if (this.current < this.data.reviews.length - 1) {
        this.current += 1;
      } else this.current = 0;
    },
    resize() {
      this.compRowHeight();
    },
    getImage(item) {
      // compute image url from object
      let url = '#';
      if (item && item.url) url = item.url;
      return url;
    },
    getImageSet(item) {
      // compute images srcset from object
      let srcset = '';
      if (item && item.sizes) {
        srcset = this.$arrToSrcset(item.sizes);
      }
      return srcset;
    },
  },
};
</script>
<style lang="scss">
  .wet-reviews {
    padding-top: 0;
  }
  .wet-reviews-slider {
    // padding: 0 150px;
    display: flex;
    position: relative;
    -webkit-transition: all ease-out 0.5s;
    -o-transition: all ease-out 0.5s;
    transition: all ease-out 0.5s;
  }
  .wet-reviews-item {
    color: #fff;
    background-color: var(--secondary);
    text-align: center;
    padding: 150px 45px 40px 45px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    position: relative;
    margin-top: 70px;
    margin-bottom: 25px;
    margin-left: 150px;
    margin-right: 150px;
    width: calc(100% - 300px);
  }
  @media only screen and (max-width: 768px) {
    .wet-reviews-item {
      padding: 90px 25px 20px 25px;
      margin-top: 35px;
      margin-left: 50px;
      margin-right: 50px;
      width: calc(100% - 100px);
    }
  }
  @media only screen and (max-width: 576px) {
    .wet-reviews-item {
      font-size: 16px;
      margin-left: 30px;
      margin-right: 30px;
      width: calc(100% - 60px);
    }
  }
  .wet-reviews-item:before,
  .wet-reviews-item:after {
    content: "";
    position: absolute;
    background: #{"rgba(var(--secondary-rgb), 0.25)"};
    -webkit-border-radius: 4px;
    border-radius: 4px;
    left: 50%;
    height: 100%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  .wet-reviews-item:before {
    width: -webkit-calc(100% - 30px);
    width: calc(100% - 30px);
    bottom: -8px;
  }
  .wet-reviews-item:after {
    width: -webkit-calc(100% - 60px);
    width: calc(100% - 60px);
    bottom: -16px;
  }
  .wet-reviews-item-photo {
    width: 184px!important;
    height: 184px!important;
    min-height: 184px!important;
    position: absolute;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    overflow: hidden;
    top: -64px;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    z-index: 2;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
  }
  @media only screen and (max-width: 768px) {
    .wet-reviews-item-photo {
      width: 96px!important;
      height: 96px!important;
      min-height: 96px!important;
      top: -30px;
    }
  }
  .wet-reviews-item-text {
    margin-bottom: 25px;
    position: relative;
    z-index: 2;
  }
  .wet-reviews-item-footer {
    position: relative;
    z-index: 2;
  }
  .wet-reviews-item-footer b {
    display: block;
  }
</style>
